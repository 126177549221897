import axios from "axios";
import {getHeaders} from "../common_js/common.js";

export default async function getautosuggestData(location) {
  let requestData = {
    "keyword": location,
    "type": "desktop"
  };
  try {
    let resp = await axios.post("/hotels/api/autosuggest", requestData, {
      headers: getHeaders(),
    });
    return resp.data;
  } catch (err) {
    return false
    }
}