import getCmsContent from "../actions/getCmsContent";
import common from "./common"
import { format, isSameDay, addDays } from 'date-fns';
import { parse1 } from "./URLParse";
import { searchClickAdobe, modifySearch } from "../adobe/searchWidget.adobe";
import { search_hotel } from "./media_tracking";
module.exports = {
    getInitialValues: async (popularDest = []) => {
        let popularDestination = []
        if (popularDest?.length)
            popularDestination = popularDest
        else {
            let request = {
                "section_names": ["popularDestination"]
            }
            let cmsData = await getCmsContent(request);
            popularDestination = cmsData?.data?.popularDestination?.data || []
        }
        let widgetValues = await module.exports.getUrlData()
        let recentSearchData = localStorage.getItem("recent_search") ? JSON.parse(localStorage.getItem("recent_search")) : []
        if (widgetValues.pageName == "Home" && recentSearchData.length) {
            widgetValues.checkin = recentSearchData?.[0]?.checkin || ""
            widgetValues.checkout = recentSearchData?.[0]?.checkout || ""
            widgetValues.roomsObj = recentSearchData?.[0]?.guest_details
        }
        delete widgetValues.pageName
        return { popularDestination, widgetValues }
    },

    getUrlData: function () {
        let isHdpPage = false, initname;
        let pageName = "Home"
        if (window.location.href.indexOf('/hoteldetails?') !== -1) {
            pageName = "Hdp"
            isHdpPage = true
        }
        else if (window.location.href.indexOf('/searchdetail?') !== -1)
            pageName = "Srp"

        let searchData = parse1(window.location.href);
        if (pageName !== "Home") {
            searchData.checkin = searchData.checkin ? searchData.checkin : format(addDays(new Date(), 1), "yyyy-MM-dd")
            searchData.checkout = searchData.checkout ? searchData.checkout : format(addDays(new Date(), 2), "yyyy-MM-dd")
            searchData.rooms = searchData.rooms ? searchData.rooms : '1a-0c'
        }
        if (pageName == "Home")
            return { pageName }
        let roomsObj = searchData.rooms !== undefined ? common.paxInfo(searchData.rooms.split('_')) : common.paxInfo('1a-0c'.split('_'));

        if (isHdpPage)
            initname = searchData.searchtext ? searchData.searchtext : searchData.name ? searchData.name : "";
        else
            initname = searchData?.region || "";


        let selectedDest = {
            destination_id: isHdpPage ? searchData.vid : searchData.destination_id,
            search_type: isHdpPage ? 21 : searchData.search_type,
            title: initname,
            country_name: searchData.country || "",
        }
        let supplier_id = searchData.supplier_id || ""
        let response = {
            selectedDest,
            roomsObj,
            checkin: searchData.checkin,
            checkout: searchData.checkout,
            supplier_id,
            pageName
        }
        return response
    },

    setRecentSearchData: function (selectedDest, checkin, checkout, rooms) {
        let recentSearch = JSON.parse(localStorage.getItem("recent_search")) || []
        let data = {
            "dest_name": selectedDest.title,
            "destination_id": selectedDest.destination_id,
            "search_type": selectedDest.search_type,
            "checkin": format(checkin, "yyyy-MM-dd"),
            "checkout": format(checkout, "yyyy-MM-dd"),
            "entry_date": format(new Date(), "yyyy-MM-dd"),
            "guest_details": rooms,
            "total_guests": rooms?.adults + rooms?.childrens,
            "country": selectedDest.country_name
        }
        const updatedSearch = [data, ...recentSearch];
        const filteredRecentSearches = updatedSearch.filter((search, index, self) => index === self.findIndex((s) => s.dest_name === search.dest_name)).slice(0, 5)

        localStorage.setItem("recent_search", JSON.stringify(filteredRecentSearches));
    },

    searchHandler: function (selectedDest, rooms, checkin, checkout, seoAdobeData) {
        let errorObj = {
            dest_err: selectedDest.title ? "" : "Please select destination",
            date_err: (!checkin ? "Checkin  cannot be empty" : !checkout ? "Checkout Date cannot be empty" : checkin > checkout ? "Check-in date should be less than ckeck-out date" : isSameDay(checkin, checkout) ? "Checkin date should be less than checkout date" : ""),
            pax_err: (rooms?.adults == 0 && rooms?.rooms?.length === 0) ? "Please select atleast 1 adult and 1 room" : rooms?.rooms?.length == 0 ? "Please select atleast 1 room" : rooms?.adults == 0 ? "Please select atleast 1 adult" : ""
        }
        if (errorObj.dest_err || errorObj.date_err || errorObj.pax_err)
            return { isError: true, errorObj }

        module.exports.setRecentSearchData(selectedDest, checkin, checkout, rooms);
        let roomString = common.getroomString(rooms);
        {/* Adobe Implementation for Search Button */ }
        let pageName = 'Hotels Homepage';
        let type = 'Search'
        if (window.location.href.includes('hoteldetails')) {
            pageName = "Hotel Details";
            type = 'Modify Search'
        }
        else if (window.location.href.includes('searchdetail')) {
            type = 'Modify Search'
            pageName = "Hotels Search Result";
        }
        if (type == 'Search') {
            seoAdobeData ? pageName = "SEO Page- Hotel" : pageName;
            searchClickAdobe(pageName, selectedDest, rooms, checkin, checkout);
            search_hotel(selectedDest, rooms, checkin, checkout)
        }
        else {
            modifySearch(pageName, selectedDest, rooms, checkin, checkout)
        }
        {/* Adobe Implementation for Search Button */ }
        // return { isError: false, errorObj }
        if (selectedDest.search_type == 21)
            module.exports.RedirectToHdp(selectedDest, checkin, checkout, roomString)
        else
            module.exports.RedirectToSrp(selectedDest, checkin, checkout, roomString)
        return { isError: false, errorObj }

    },
    RedirectToSrp: async (selectedDest, checkin, checkout, paxData) => {
        let urlData = {
            "region": selectedDest.title,
            "mop": "accrual",
            "view": "tiles",
            "country": selectedDest.country_name,
            "destination_id": selectedDest.destination_id,
            "search_type": selectedDest.search_type,
            "checkin": format(new Date(checkin), 'yyyy-MM-dd'),
            "checkout": format(new Date(checkout), 'yyyy-MM-dd'),
            "rooms": `${paxData}`,
            "vid": selectedDest.destination_id
        }
        common.redirect("/searchdetail", urlData)
    },

    RedirectToHdp: async (selectedDest, checkin, checkout, paxData) => {
        let urlValues = await module.exports.getUrlData()
        let urlData = {
            "searchtext": selectedDest.title,
            "mop": "accrual",
            "country": selectedDest.country_name,
            "vid": selectedDest.destination_id,
            "search_type": selectedDest.search_type,
            "checkin": format(new Date(checkin), 'yyyy-MM-dd'),
            "checkout": format(new Date(checkout), 'yyyy-MM-dd'),
            "rooms": `${paxData}`,
            "supplier_id": urlValues.supplier_id || ""
        }
        common.redirect("/hoteldetails", urlData)
    }
}

// {
//     "id": "9",
//     "country_code": "IN",
//     "country_name": "India",
//     "property_count": "3",
//     "sub_title": "OYO 8095 Hotel Taj Castle, Agra, India",
//     "title": "OYO 8095 Hotel Taj Castle, Agra, India",
//     "destination_id": "2888546",
//     "destination_type_name": "Property",
//     "search_type": 21,
//     "property_type_name": "Hotel",
//     "property_type_count": "2"
// }
