import React from "react";
import { Skeleton } from "@vernost_dev/vms-react-components/dist/Skeleton";
import { getClassName } from '@/common_js/common';
import * as css from '@/styles/Skeleton.module.css'
function SearchPageSkeletonDesktop() {
  return (
    <div className={getClassName(css[''])}>
      <SearchWidgetSkeleton />
      <PoweredBySkeleton />
      <FilterSkeleton />
      <SortBySkeleton />
      <HotelsSkeleton />
      <OffersSkeleton />
      <HotelCardImageSkeleton />
    </div>
  )
}

const OffersSkeleton = () => {
  return (
    <div style={{ maxWidth: "1160px", margin: '0 auto 20px' }}>
      <div style={{ width: "98%", margin: '0px' }}>
        <Skeleton
          height={70}
          additionalClassName={[css["react-loading-skeleton"]].join(" ")}
        />

      </div>

    </div>
  )
}


const OffersSkeletonOld = () => {
  return (
    <div style={{ maxWidth: "1160px", margin: '0 auto 20px' }}>
      <div className={getClassName(css['d_flex_between'])}>
        <Skeleton
          width={292}
          height={70}
          additionalClassName={[css["react-loading-skeleton"], css["radius"],].join(" ")}
        />

        <Skeleton
          width={292}
          height={70}
          additionalClassName={[css["react-loading-skeleton"], css["radius"],].join(" ")}
        />

        <Skeleton
          width={292}
          height={70}
          additionalClassName={[css["react-loading-skeleton"], css["radius"],].join(" ")}
        />

      </div>

    </div>
  )
}
const SearchWidgetSkeleton = () => {
  return (
    <div className={getClassName(css['containersearch'])}>
      <div className={getClassName(css['main-input-going'])}>
        <div className={getClassName(css['input-autocomplte-srp'])} style={{ width: '276px' }}>
          <Skeleton
            width={100}
            height={20}
            variant={'rounded'}
          />
        </div>

        <div className={getClassName(css['input-autocomplte-srp'])} style={{ width: '305px' }}>
          <Skeleton
            width={180}
            height={20}
            variant={'rounded'}
          />
        </div>
        <div className={getClassName(css['input-autocomplte-srp'])} style={{ width: '300px' }}>
          <Skeleton
            width={150}
            height={20}
            variant={'rounded'}
          />
        </div>
        <div className={getClassName(css['input-autocomplte-srp-button'])}>
          <Skeleton
            width={100}
            height={20}
            variant={'rounded'}
          />
        </div>
      </div>
    </div>
  )
}

const PoweredBySkeleton = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', maxWidth: "1280px", margin: '16px auto 0' }}>
      <Skeleton
        width={200}
        height={20}
        additionalClassName={[css['react-loading-skeleton'], ['margib_btm']].join(" ")}
      />
    </div>
  )
}

const FilterSkeletonHead = () => {
  return (
    <div className={getClassName(css['left_search'])}>

      <div className={getClassName(css['filter-widget-search-section'])}>
        <Skeleton width={18} height={18} />
        <Skeleton width={100} height={18} />
      </div>
    </div>
  )
}

const FilterSkeleton = () => {
  return (
    <div className={getClassName(css['left_search'])}>
      <div className={getClassName(css['d_flex_between'], css['p16'])}>
        <Skeleton
          width={85}
          height={24}

        />
        <Skeleton
          width={10}
          height={12}

        />
      </div>
      <div className={getClassName(css['d_flex'], css['quick-filter-checkbox-unchecked'])}>
        <Skeleton
          width={18}
          height={18}
          additionalClassName={[css['react-loading-skeleton']].join(" ")}
        />
        <Skeleton
          width={80}
          height={20}
          additionalClassName={[css['react-loading-skeleton']].join(" ")}
        />
      </div>
      <div className={getClassName(css['d_flex'], css['quick-filter-checkbox-unchecked'])}>
        <Skeleton
          width={18}
          height={18}
          additionalClassName={[css['react-loading-skeleton']].join(" ")}
        />
        <Skeleton
          width={80}
          height={20}
          additionalClassName={[css['react-loading-skeleton']].join(" ")}
        />
      </div>
      <div className={getClassName(css['d_flex'], css['quick-filter-checkbox-unchecked'])}>
        <Skeleton
          width={18}
          height={18}
          additionalClassName={[css['react-loading-skeleton']].join(" ")}
        />
        <Skeleton
          width={80}
          height={20}
          additionalClassName={[css['react-loading-skeleton']].join(" ")}
        />
      </div>

      <div className={getClassName(css['d_flex_between'], css['p16'])}>
        <Skeleton
          width={85}
          height={24}

        />
        <Skeleton
          width={10}
          height={12}

        />
      </div>



      <div className={getClassName(css["filterWidget_price_range_container"])}>

        <div className={getClassName(css["price_range_progress_bar"])}>
          <Skeleton height={20} width={20} variant="circular" />
          <Skeleton height={5} className={getClassName(css["price_range_progress_bar_arrow"])} />
          <Skeleton height={20} width={20} variant="circular" />
        </div>
        <div className={getClassName(css["price_range_progress_price"])}>
          <Skeleton height={21} width={100} />
          <Skeleton height={21} width={100} />
        </div>

      </div>

      <div className={getClassName(css['d_flex_between_16'])}>
        <div className={getClassName(css['g10'])}>
          <Skeleton width={18} height={18} />
          <Skeleton width={100} height={18} />
        </div>
        <Skeleton width={40} height={18} />
      </div>

      <div className={getClassName(css['d_flex_between_16'])}>
        <div className={getClassName(css['g10'])}>
          <Skeleton width={18} height={18} />
          <Skeleton width={100} height={18} />
        </div>
        <Skeleton width={40} height={18} />
      </div>

      <div className={getClassName(css['d_flex_between_16'])}>
        <div className={getClassName(css['g10'])}>
          <Skeleton width={18} height={18} />
          <Skeleton width={100} height={18} />
        </div>
        <Skeleton width={40} height={18} />
      </div>

      <div className={getClassName(css['d_flex_between_16'])}>
        <div className={getClassName(css['g10'])}>
          <Skeleton width={18} height={18} />
          <Skeleton width={100} height={18} />
        </div>
        <Skeleton width={40} height={18} />
      </div>

      <div className={getClassName(css['d_flex_between_16'])}>

        <Skeleton width={150} height={18} />
      </div>


      <div>
        <div className={getClassName(css['d_flex_between'], css['p16'])}>
          <Skeleton width={85} height={24} />
          <Skeleton width={10} height={12} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <Skeleton width={100} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <Skeleton width={100} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <Skeleton width={100} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <Skeleton width={100} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <Skeleton width={150} height={18} />
        </div>
      </div>

      <div>
        <div className={getClassName(css['d_flex_between'], css['p16'])}>
          <Skeleton width={85} height={24} />
          <Skeleton width={10} height={12} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <div className={getClassName(css['g10'], css['align_center'])}>
              <Skeleton height={10} width={10} variant="circular" />
              <Skeleton height={10} width={10} variant="circular" />
              <Skeleton height={10} width={10} variant="circular" />
              <Skeleton height={10} width={10} variant="circular" />
              <Skeleton height={10} width={10} variant="circular" />
            </div>
            <Skeleton width={50} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <div className={getClassName(css['g10'], css['align_center'])}>
              <Skeleton height={10} width={10} variant="circular" />
              <Skeleton height={10} width={10} variant="circular" />
              <Skeleton height={10} width={10} variant="circular" />
              <Skeleton height={10} width={10} variant="circular" />
              <Skeleton height={10} width={10} variant="circular" />
            </div>
            <Skeleton width={50} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>


        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <div className={getClassName(css['g10'], css['align_center'])}>
              <Skeleton height={10} width={10} variant="circular" />
              <Skeleton height={10} width={10} variant="circular" />
              <Skeleton height={10} width={10} variant="circular" />
              <Skeleton height={10} width={10} variant="circular" />
              <Skeleton height={10} width={10} variant="circular" />
            </div>
            <Skeleton width={50} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>
      </div>

      <div>
        <div className={getClassName(css['d_flex_between'], css['p16'])}>
          <Skeleton width={85} height={24} />
          <Skeleton width={10} height={12} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <Skeleton width={100} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <Skeleton width={100} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <Skeleton width={100} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <Skeleton width={100} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <Skeleton width={150} height={18} />
        </div>
      </div>


      <div>
        <div className={getClassName(css['d_flex_between'], css['p16'])}>
          <Skeleton width={85} height={24} />
          <Skeleton width={10} height={12} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <Skeleton width={100} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <Skeleton width={100} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <Skeleton width={100} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <Skeleton width={100} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <Skeleton width={150} height={18} />
        </div>
      </div>


      <div>
        <div className={getClassName(css['d_flex_between'], css['p16'])}>
          <Skeleton width={85} height={24} />
          <Skeleton width={10} height={12} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <Skeleton width={100} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <Skeleton width={100} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <Skeleton width={100} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <Skeleton width={100} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <Skeleton width={150} height={18} />
        </div>
      </div>


      <div>
        <div className={getClassName(css['d_flex_between'], css['p16'])}>
          <Skeleton width={85} height={24} />
          <Skeleton width={10} height={12} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <Skeleton width={100} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <Skeleton width={100} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <Skeleton width={100} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <div className={getClassName(css['g10'])}>
            <Skeleton width={18} height={18} />
            <Skeleton width={100} height={18} />
          </div>
          <Skeleton width={40} height={18} />
        </div>

        <div className={getClassName(css['d_flex_between_16'])}>
          <Skeleton width={150} height={18} />
        </div>
      </div>


    </div>
  )
}

const SortBySkeleton = () => {
  return (
    <div className={getClassName(css['right_search'])}>
      <div className={getClassName(css['prop-found-main'])}>
        <Skeleton width={200} height={22} variant={"rounded"} />
      </div>

      {/*  : null}
           {componentToShow.includes("sortBy") ?  */}

      <div className={getClassName(css['prop-ratings-inner'])}>
        <div className={getClassName(css['filter'])}>
          <Skeleton
            width={106}
            height={20}
            variant={"rounded"}
          />
        </div>
        <div className={getClassName(css['filter'])}>
          <Skeleton
            width={106}
            height={20}
            variant={"rounded"}
          />
        </div>
        <div className={getClassName(css['filter'])}>
          <Skeleton
            width={156}
            height={20}
            variant={"rounded"}
          />
        </div>
        <div className={getClassName(css['filter'])}>
          <Skeleton
            width={106}
            height={20}
            variant={"rounded"}
          />
        </div>
      </div>
    </div>
  )
}

const CardSkeleton = () => {
  return (<div className={getClassName(css['corosal-slide'])}>
    <div className={getClassName(css['inner_carousel_block'])}>
      <Skeleton width={221} height={249} />
    </div>

    <div className={getClassName(css['inner_text_block'])}>
      <div className={getClassName(css['thumb-top-heading-row'])}>
        <div className={getClassName(css['innerreviewrow'])}>

          <div className={getClassName(css['inner-review-row'])}>

            <div className={getClassName(css['inner-title-section'])}>
              <div className={getClassName(css['inner-title-section_header'])}>
                <Skeleton width={401} height={20} />

                <Skeleton width={101} height={20} />
              </div>
              <div className={getClassName(css['inner-type-section-container'])}>
                <Skeleton width={34} height={18} />
                <div className={getClassName(css['inner-details-section'])}>
                  <Skeleton width={16} height={16} variant={'rounded'} />
                  <Skeleton width={16} height={16} variant={'rounded'} />
                  <Skeleton width={16} height={16} variant={'rounded'} />
                  <Skeleton width={16} height={16} variant={'rounded'} />
                  <Skeleton width={16} height={16} variant={'rounded'} />
                </div>
              </div>
              <div className={getClassName(css['inner-details-section'])}>
                <Skeleton width={316} height={20} />
              </div>
            </div>

            <div className={getClassName(css['review-rating'])}>
              <div className={getClassName(css['review-rating-sub-container'])}>
                <Skeleton width={24} height={24} variant={"circular"} />
                <Skeleton width={126} height={22} />
              </div>
              <div className={getClassName(css['review-rating-inner'])}>
                <Skeleton width={100} height={20} />
              </div>
            </div>

          </div>
        </div>
        <div className={getClassName(css['room-and-amenities-section'])}>
          <div className={getClassName(css['room-and-amenities-section-inner'])}>
            <div><Skeleton width={100} height={20} /></div>

            <Skeleton width={50} height={20} />
          </div>

          <div className={getClassName(css['price-row'])}>
            <div className={getClassName(css['review-rating-sub-container'])}>
              <Skeleton width={50} height={24} />
              <Skeleton width={40} height={22} />
            </div>
            <div className={getClassName(css['review-rating-inner'])}>
              <Skeleton width={100} height={20} />
            </div>
            <div className={getClassName(css['review-rating-inner'])}>
              <Skeleton width={150} height={20} />
            </div>
          </div>






        </div>
      </div>
    </div>

  </div>)
}

const HotelsSkeleton = () => {
  {/* card */ }
  const sections = [];
  for (let i = 0; i < 10; i++) {
    sections.push(<CardSkeleton key={i} />);
  }

  return <><div className={getClassName(css['right_search'])}>{sections}</div></>;
}

const HotelCardImageSkeleton = () => {
  return (
    <div className={getClassName(css['right_search'])}>
      <div style={{ padding: '16px 20px 16px 15px', gap: '20px', borderRadius: '12px', border: '1px solid rgba(0, 0, 0, .04)', marginBottom: '16px' }} className={getClassName(css['d_flex'])}>
        <Skeleton
          width={230}
          height={250}
          additionalClassName={[css['react-loading-skeleton']].join(" ")}
        />
      </div>
    </div>
  )
}

export { SearchPageSkeletonDesktop, SearchWidgetSkeleton, PoweredBySkeleton, FilterSkeletonHead, FilterSkeleton, SortBySkeleton, HotelsSkeleton, HotelCardImageSkeleton, OffersSkeleton };