import React from "react";
import { InputText } from "@vernost_dev/vms-react-components/dist/InputText";

const VmsInputText = (props) => {
  const { placeholder, value, label,name,type,id,onClick,className,readOnly,disabled, onChange = () => {},tabIndex,maxLength } = props;

  return (
    <InputText
      label={label}
      value={value}
      name={name}
      type={type}
      id={id}
      placeholder={placeholder}
      additionalClassName={className}
      // onClick={(e) => onClick(e)}
      onChange={(e) => onChange(e)}
      tabIndex={tabIndex}
      maxLength={maxLength}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
};

export { VmsInputText };
