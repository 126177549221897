// import { searchClick } from "./adobe"
import Adobe from './adobe'
import deepMergeObject from './deepMergeObject';
import { format } from 'date-fns';

const pageInfo = {
    siteSection: "Hotel Booking Flow",
    language: "en",
    journeyFlow: "Hotel Booking Flow",
    lob: "Hotel",
    projectName: "Hotel"
};

let userData = {
    "customerID": '',
    "type": 'Anonymous'
};

let errorDetails = {
    id: "",
    type: "",
    source: "",
    displayMessage: "",
    url: "",
    statusCode: "",
    statusMessage: ""
}

module.exports = {
    setUserDetails: function (userInfo) {
        try {
            if (userInfo) {
                userData = {
                    "type": userInfo?.role_code,
                    "customerID": Adobe.generateHash(userInfo.customer_id),
                    "tier": userInfo?.tier,
                    "FFNumber": userInfo?.FFNumber || "",
                }
            }
        } catch (error) {
            console.log("adobe err:", error)
        }
    },
    searchClickAdobe: function (pageName, selectedDestinationDetails, rooms, startDate, endDate) {
        try {
            pageInfo.pageName = pageName
            pageInfo.siteSection = pageName;
            let city = "";
            let country = selectedDestinationDetails?.sub_title?.split(",")[selectedDestinationDetails?.sub_title?.split(",").length - 1];
            let adobeData = {
                interactionType: "Link/ButtonClick",
                event: "searchHotels",
                page: {
                    eventInfo: {
                        name: "Search Hotels",
                        position: "",
                        component: "Booking Widget",
                    },
                    pageInfo: {
                        pageName: pageInfo.pageName,
                        siteSection: pageInfo.siteSection,
                        language: pageInfo.language,
                        journeyFlow: pageInfo.journeyFlow,
                        lob: pageInfo.lob,
                    },
                    error: { ...errorDetails },
                },
                product: {
                    productInfo: {
                        totalPax: rooms.adults + rooms.childrens,
                        adultPax: rooms.adults,
                        childPax: rooms.childrens,
                        infantPax: 0,
                        hotelSearchDetails: {
                            searchType: selectedDestinationDetails.search_type == 21 ? "Property" : "Destination",
                            searchKeyword: selectedDestinationDetails.title,
                            checkinDate: format(startDate, 'dd-MM-yyyy'),
                            checkoutDate: format(endDate, 'dd-MM-yyyy'),
                            roomCount: rooms?.rooms.length,
                            searchCity: city,
                            searchCountry: country
                        }
                    }
                },
                loyalty: {
                    pointsEarn : "0",
                    pointsBurn : "0",
                },
                user: userData,
                error: {}
            }
            const commonData = Adobe.pageInteractions(adobeData.page.pageInfo, adobeData.page.eventInfo, adobeData.user)
            if (commonData) {
                const combined = deepMergeObject(commonData, adobeData);
                window.adobeDataLayer = window.adobeDataLayer || []
                window.adobeDataLayer.push(combined)
            }
        } catch (error) {
            console.log("adobe err:", error)
        }
    },

    modifySearch: function (pageName, selectedDestinationDetails, rooms, startDate, endDate) {
        try {
            pageInfo.pageName = pageName
            let roomsCount = rooms.rooms.length;
            let city = "";
            let country = selectedDestinationDetails.country_name;
            let adobeData = {
                interactionType: "Link/ButtonClick",
                event: "searchHotels",
                // user: userData,
                page: {
                    eventInfo: {
                        name: "Modify Search",
                        position: "",
                        component: "Booking Widget",
                        outboundLinkName: "",
                        outboundLinkURL: "",
                    },
                    pageInfo: {
                        pageName: pageInfo.pageName,
                        siteSection: pageInfo.siteSection,
                        language: pageInfo.language,
                        journeyFlow: pageInfo.journeyFlow,
                        lob: pageInfo.lob
                    },
                    error: {},
                },
                product: {
                    productInfo: {
                        currencyCode: "INR",
                        hotelSearchDetails: {
                            searchKeyword: selectedDestinationDetails.title,
                            searchType: localStorage.getItem("adobe_dest_type") || "Property",
                            checkinDate: format(startDate, 'dd-MM-yyyy'),
                            checkoutDate: format(endDate, 'dd-MM-yyyy'),
                            roomcount: roomsCount,
                            searchCity: city,
                            searchCountry: country
                        },
                        totalPax: rooms.adults + rooms.childrens,
                        adultPax: rooms.adults,
                        childPax: rooms.childrens,
                        infantPax: 0,
                    }
                },
                loyalty: {
                    pointsEarn : "0",
                    pointsBurn : "0",
                },
            }

            const commonData = Adobe.pageInteractions(adobeData.page.pageInfo, adobeData.page.eventInfo, adobeData.user)
            if (commonData) {
                const combined = deepMergeObject(commonData, adobeData);
                window.adobeDataLayer = window.adobeDataLayer || []
                window.adobeDataLayer.push(combined)
            }

        } catch (error) {
            console.log("adobe err:", error)
        }
    }
}