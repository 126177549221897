import React,{memo,useMemo} from "react";
import {  DateRangeCalendar  } from "@vernost_dev/vms-react-components/dist/DateRangeCalendar";
import {  DateRangeCalendarMobile  } from "@vernost_dev/vms-react-components/dist/DateRangeCalendarMobile";



// Functional component with React.memo
const VmsDateRangeCalendar = React.memo((props) => {
  const computedValue = useMemo(() => {
    // Expensive computation based on prop1 and prop2
    return props;
  }, [props]);
  const {
    indicatorColor,
    startDate,
    endDate,
    noofMonthView,
    inputBoxLabel,
    mode,
    openCalendar,
    currentSelection,
    customInputView,
    additionalClassName,
    dateDisplayFormat,
    hideHeader,
    showDisabled,
    showEndDate,
    autoFocus,
    autoInputFocusData,
    showTodayDate,
    todaysDateCss,
    disabledColor,
    arrowImages,
    showCalendarOnOutsideClick,
    onSingleDateChange = () =>{},
    onMultiDateChange=()=>{},
    onCalendarVisibilityChange= () =>{},
    cancelButtonType,
    maxBookingDays,
    accessibleId
  } = props;
  return (
    <DateRangeCalendar
      openCalendar={openCalendar}
      indicatorColor={indicatorColor}
      startDate={startDate}
      endDate={endDate}
      noofMonthView={noofMonthView}
      inputBoxLabel={inputBoxLabel}
      mode={mode}
      hideHeader={hideHeader}
      customInputView={customInputView}
      currentSelection={currentSelection}
      additionalClassName={additionalClassName}
      dateDisplayFormat={dateDisplayFormat}
      showDisabled={showDisabled}
      showEndDate={showEndDate}
      autoFocus={autoFocus}
      autoInputFocusData={autoInputFocusData}
      showTodayDate={showTodayDate}
      todaysDateCss={todaysDateCss}
      disabledColor={disabledColor}
      arrowImages={arrowImages}
      showCalendarOnOutsideClick={showCalendarOnOutsideClick}
      onSingleDateRangeSelectionChange={(result) => {
        onSingleDateChange(result)
      }}
      onDateSelectionChange={(result) => {
        onMultiDateChange(result)
      }}
      onCalendarVisibilityChange={(val)=>{
        onCalendarVisibilityChange(val)
      }}
      cancelButtonType={cancelButtonType}
      maxBookingDays={maxBookingDays}
      accessibleId={accessibleId}
    />
  );

  // Component logic
});

const VmsDateRangeCalendarMobile = (props) => {
  const {
    startDate,
    endDate,
    openCalendar,
    additionalClassName,
    onDoneButtonClick,
    initialFocusOn,
    startDateLabelText,
    endDateLabelText,
    showTodayDate,
    todaysDateCss,
    disabledColor,
    customInputView,
    onItemClick = () =>{},
    showCancelButton,
    cancelButtonText,
    onCancelButtonClick = () => {},
    maxBookingDays,
    disabledPreviousDate
  } = props;
  return (
    <DateRangeCalendarMobile           
      startDate={startDate}
      endDate={endDate}
      openCalendar={openCalendar}
      additionalClassName={additionalClassName}
      onDoneButtonClick={onDoneButtonClick}
      initialFocusOn={initialFocusOn}
      startDateLabelText={startDateLabelText}
      endDateLabelText={endDateLabelText}
      showTodayDate={showTodayDate}
      todaysDateCss={todaysDateCss}
      disabledColor={disabledColor}
      customInputView={customInputView}
      onItemClick={(result)=>onItemClick(result)}
      showCancelButton={showCancelButton}
      cancelButtonText={cancelButtonText}
      onCancelButtonClick={result=>onCancelButtonClick(result)}
      maxBookingDays={maxBookingDays}
      disabledPreviousDate={disabledPreviousDate}
    />
  );
};

export { VmsDateRangeCalendar, VmsDateRangeCalendarMobile };
