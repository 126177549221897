import React, { useState, useEffect, Fragment, useRef } from 'react';
import axios from "axios"
import SearchPageSkeletonDesktop from '@/components/Skeletons/SearchPage/SkeletonDesktop';
import config from "../../../config/config_api.json"
import { addDays, format, isBefore } from 'date-fns';
import getautosuggestData from '@/actions/autosuggest';
import { getInitialValues, searchHandler } from '@/common_js/searchWidget';
import { setUserDetails } from "../../../adobe/searchWidget.adobe";
import * as css from '../../../styles/SearchWidget.module.css'
import dynamic from 'next/dynamic';
import Image from 'next/image';
import static_config from "../../../config/static_config.json"
// const VmsButton = dynamic(() => import("../../VmsComponent/Button").then((module) => module.VmsButton))
// const VmsVariantOfAutoComplete = dynamic(() => import("../../VmsComponent/VariantOfAutoComplete").then((module) => module.VmsVariantOfAutoComplete))
// const VmsDateRangeCalendar = dynamic(() => import("../../VmsComponent/DateRangeCalendar").then((module) => module.VmsDateRangeCalendar))
// const VmsInputText = dynamic(() => import("../../VmsComponent/Input").then((module) => module.VmsInputText))
// const VMSPaxSelection = dynamic(() => import("../../VmsComponent/PaxSelection").then((module) => module.VMSPaxSelection))
import { VmsButton } from '../../VmsComponent/Button/';
import { VmsVariantOfAutoComplete } from '../../VmsComponent/VariantOfAutoComplete/';
import { VmsInputText } from "../../VmsComponent/Input/";
import { VmsDateRangeCalendar } from '../../VmsComponent/DateRangeCalendar/'
import { VMSPaxSelection } from '../../VmsComponent/PaxSelection'
import seo_config from "../../../config/config_seo.json"
import { getClassName, getLocalImagePath } from '@/common_js/common';
let searchWidgetErr = {
    dest_err: "",
    pax_err: "",
    date_err: ""
}
let roomConfig = static_config.roomConfig
let controller
// let count = 0;
let childAge = false;
let initRoom = static_config.roomConfig.defaultRoomsVal;
function SearchWidget(props) {

    const { cityName, seoAdobeData, type, countryName } = props;

    const [showSuggestionList, setshowSuggestionList] = useState(false)
    const [showCalendar, setshowCalendar] = useState(false)
    const [showPaxDropdown, setshowPaxDropdown] = useState(false)
    const [autosuggestList, setautosuggestList] = useState([])
    const [autoSuggestValue, setautoSuggestValue] = useState(props?.searchObj?.title || cityName || "")
    const [checkin, setcheckin] = useState(addDays(new Date(), 1));
    const [checkout, setcheckout] = useState(addDays(new Date(), 2));
    const [selectedDest, setSelectedDest] = useState(props?.searchObj || {});
    const [errorObj, seterrorObj] = useState(JSON.parse(JSON.stringify(searchWidgetErr)))
    const [rooms, setrooms] = useState(JSON.parse(JSON.stringify(initRoom)))
    const [popularDestination, setpopularDestination] = useState(props.popularDestination || [])
    const [loadingWidget, setloadingWidget] = useState(props.isLoading)
    const [hasInputStarted, setHasInputStarted] = useState(false);
    const previousRoomData = useRef(null);
    const hasCalledOnce = useRef(false);

    useEffect(() => {
        async function fetchData() {
            //setloadingWidget(true)
            {/* Adobe implementation for User Details in SearchWidget*/ }
            setUserDetails(props.userData)
            {/* Adobe implementation for User Details in SearchWidget*/ }

            let initialValues = await getInitialValues(props.popularDestination)
            setpopularDestination(initialValues.popularDestination)
            setrooms(JSON.parse(JSON.stringify(initRoom)))
            const widgetValues = initialValues?.widgetValues
            let todayDate = format(new Date(), "yyyy-MM-dd") + "T00:00:00"

            if (widgetValues && Object.keys(widgetValues).length) {
                if (isBefore(new Date(widgetValues.checkin + "T00:00:00"), new Date(todayDate)))
                    widgetValues.checkin = format(addDays(new Date(), 1), "yyyy-MM-dd")
                if (isBefore(new Date(widgetValues.checkout + "T00:00:00"), new Date(widgetValues.checkin)))
                    widgetValues.checkout = format(addDays(new Date(), 2), "yyyy-MM-dd")

                setautoSuggestValue(widgetValues?.selectedDest?.title || autoSuggestValue)
                setSelectedDest(widgetValues?.selectedDest || selectedDest);
                if (type === "seoSearch") {
                    setcheckin(addDays(new Date(), seo_config?.seoPageConfig?.checkinGap));
                    setcheckout(addDays(new Date(), seo_config?.seoPageConfig?.checkoutGap));
                } else {
                    setcheckin(new Date(widgetValues.checkin + "T00:00:00"));
                    setcheckout(new Date(widgetValues.checkout + "T00:00:00"));
                }
                setrooms(widgetValues.roomsObj)
            }
            setloadingWidget(false)
        }
        // if (count == 0)
        fetchData()
        // return (() => {
        //     count++;
        // })
    }, []);
    useEffect(() => {
        if (props.searchObj && Object.keys(props.searchObj).length) {
            const valueToSet = props.searchObj?.cityName
                ? props.searchObj.cityName
                : props.searchObj?.title;
            setautoSuggestValue(valueToSet);
            setSelectedDest(props.searchObj);
        }
    }, [props?.searchObj]);



    // Autosuggest Function

    // {
    //     "id": "0",
    //     "country_code": "IN",
    //     "country_name": "India",
    //     "city_name": "",
    //     "state_name": "",
    //     "property_count": "1193",
    //     "title": "Mumbai, Maharashtra, India",
    //     "sub_title": "Mumbai, India",
    //     "destination_id": "1149271",
    //     "destination_type": "Region",
    //     "search_type": 4,
    //     "property_type": "",
    //     "property_type_count": ""
    // }
    function RenderItem(item, highlighted) {
        let icon = item.search_type == 21 ? "hotel_icon.svg" : item.search_type == 8 ? "airport_icon.svg" : "location_icon.svg"
        return (
            // autoSuggestValue.length > 0 ?
            <div className={getClassName([css["im-autosuggested"]])}>
                <div key={item.id} className={getClassName(css['desktop-li-recent-desktop'])}>
                    <div className={getClassName(css['location-suggestion-icon-wrapper'])}>
                        <Image src={getLocalImagePath(icon)} alt="hompage_icon" width={24} height={24} />
                    </div>
                    <div className={getClassName(css['suggestions-location-container'])}>
                        <li
                            className={getClassName(css['selectedoption'])}
                            id={highlighted ? 'selectedoption' : ''}
                            role="option"
                            data-activedescendant={highlighted}
                            aria-selected={highlighted}
                            style={{ padding: '' }}
                        >
                            {item.search_type == 21 ? item.sub_title : item.title}
                        </li>
                        <li
                            className={[css['selectedoption-subtitle']].join("")}
                            id={highlighted ? 'selectedoption' : ''}
                            role="option"
                            data-activedescendant={highlighted}
                            aria-selected={highlighted}
                            style={{ padding: '' }}
                        >
                            {item.search_type == 21 ? `${item.property_type != "NA" ? item.property_type : item.destination_type_name} | ${item.city_name ? `${item.city_name},` : ""} ${item.state_name ? `${item.state_name},` : ""} ${item.country_name || ""}` : `${item.destination_type_name} | ${item.property_count} properties`}
                        </li>
                    </div>
                </div>
            </div>
            // :
            // <div className={getClassName([css["homepage-search-location"]])}>
            //     <div className={getClassName([css["homepage-search-location-details"]])}>

            //         <button className={getClassName([css["homepage-search-location-popular-dest"]])}>
            //             <div className={getClassName([css["homepage-search-location-image"]])}>
            //                 <img src="/hotels/assets/images/location_suggestions_icon.svg" alt="hompage_icon" />
            //             </div>
            //             <p className={getClassName([css["homepage-search-location-title"]])}>{item.title}</p>
            //         </button>

            //     </div>
            // </div> 
        );
    }

    const paxChangeCallback = (roomData) => {
        const allChildrenHaveZeroAge = roomData.rooms.every(room =>
            room.childAge.every(child => child.age === 0)
        );
        if (roomData.childrens > 0 && allChildrenHaveZeroAge) {
            if (hasCalledOnce.current) {
                return;
            }
            hasCalledOnce.current = true;
        }
        if (JSON.stringify(previousRoomData.current) === JSON.stringify(roomData)) {
            return;
        }
        setrooms(roomData);
        previousRoomData.current = roomData;
    };

    const RenderMenu = (children) => {
        return (
            <div className={[css["ig-homepage-search"], css[autoSuggestValue?.length > 0 ? "ig-homepage-search-suggestion" : ""]].join(" ")}>
                {/* <div className={[css["ig-homepage-search"]].join(" ")}> */}
                {!autosuggestList.length ?
                    <div className={[css["jp-no-result-found"]].join("")}>
                        <Image src={getLocalImagePath("No-result-found-img.svg")} width={24} height={24} style={{ width: '100%', height: 'auto', maxHeight: '100%' }}></Image>
                        <div className={[css['jp-no-result-found-text']].join("")}>Oops! No Results Found!</div>
                    </div>
                    :
                    <div className={[css["ig-homepage-search-inner"]].join(" ")}>
                        <div className={[css["ig-title"]].join(" ")}>{autoSuggestValue?.length > 0 ? 'Suggestions' : 'Popular Destinations'}</div>
                        <div id="ex1-label" />
                        <ul
                            role="listbox"
                            id="ex1-listbox"
                            aria-labelledby="ex1-label"
                            className={'ul-style'}
                        >
                            <div className={getClassName(css["jp-mobile-recently-searched"])}>{children}</div>
                            {/* <div className={getClassName(css[autoSuggestValue.length > 0  ? 'jp-mobile-recently-searched' : 'jp-mobile-recently-searched-popular-dest'])}>{children}</div> */}
                        </ul>
                    </div>
                }
            </div>
        )
    }

    async function callAutosuggestApi(value) {
        value = value && value?.trim() ? value : ""
        setautoSuggestValue(value);
        setSelectedDest({})
        let suggestionList = popularDestination
        if (controller)
            controller.abort()
        if (!value) {
            setautosuggestList(suggestionList)
            setshowSuggestionList(true)
            return
        }

        controller = new AbortController();

        axios.post(`/hotels/api/autosuggest`, {
            "keyword": value || "",
            "type": "desktop"
        },
            {
                signal: controller.signal,
            }).then((autoSuggestResult) => {
                setautosuggestList(autoSuggestResult?.data?.data || [])
                setshowSuggestionList(true)
            }).catch((thrown) => {
                if (axios.isCancel(thrown))
                    console.log("Request cancelled", thrown.message);

            })
    }

    function onSelect(value, item) {
        setSelectedDest(item);
        setshowCalendar(true)
        setshowPaxDropdown(false)
        setautoSuggestValue(item.title);
        seterrorObj(prevErrorObj => { return { ...prevErrorObj, dest_err: "" } })
    }
    // Autosuggest Function


    // calendar functions

    const onDateSelectionChange = (result) => {
        if (result.dateType == "endDate") {
            setcheckout(result.date)
            let dateError = isBefore(result.calendarState.startDate, result.calendarState.endDate) ? "" : "Checkin date should be less than checkout date"
            if (!dateError)
                setshowPaxDropdown(true);
            seterrorObj({ ...errorObj, date_err: dateError })
        }

        if (result.dateType == "startDate") {
            const newCheckinDate = result.date;
            setcheckin(newCheckinDate);
            const newCheckoutDate = addDays(newCheckinDate, 1);
            setcheckout(newCheckoutDate);

            // Revalidate dates when startDate changes
            // let dateError = isBefore(newCheckinDate, newCheckoutDate) ? "" : "Checkin date should be less than checkout date";
            seterrorObj({ ...errorObj, date_err: "" });
        }
    }


    // calendar functions

    async function handleSearch(type) {
        setshowCalendar(false)
        setshowSuggestionList(false)
        setshowPaxDropdown(false)
        if (type == "Search") {
            if (selectedDest.search_type == 21)
                localStorage.setItem("adobe_dest_type", "Property")
            else
                localStorage.setItem("adobe_dest_type", "Destination")

        }

        let resp = await searchHandler(selectedDest, rooms, checkin, checkout, seoAdobeData)
        if (resp.isError) {
            seterrorObj(resp.errorObj)
            if (resp.errorObj.dest_err)
                setautoSuggestValue("")
        }
    }

    function Type1Widget() {
        return (
            <div className={[css['search-widget-container']].join(' ')} id={"searchWidgetContainer"}>
                <div className={[css['search-widget-fields']].join(' ')}>
                    {/* autosuggest */}
                    <div className={[css['input-field-container']].join(' ')}
                    // onClick={() => { callAutosuggestApi("") }}
                    >

                        <VmsVariantOfAutoComplete
                            onInputClick={() => {
                                setshowSuggestionList(!showSuggestionList)
                                if (showCalendar) {
                                    setshowCalendar(!showCalendar)
                                }
                            }}
                            items={autosuggestList}
                            searchLabel={"Destination or Property Name"}
                            className="atou-testing"
                            additionalClassName={[css["test-autocomplete"]].join(" ")}
                            autoHighlight={false}
                            getItemValue={(item) => { return item.title }}
                            renderItem={RenderItem}
                            value={autoSuggestValue}
                            onFocus={() => { callAutosuggestApi(""); }}
                            onChange={(e) => callAutosuggestApi(e.target.value)}
                            onSelect={(value, item) => { onSelect(value, item) }}
                            inputProps={{
                                placeholder: 'Where to?',
                                id: 'search-flyout-mobile-input',
                                className: `${(autoSuggestValue === '') ? 'jp-input-empty' : 'jp-input-value'} new-where-to-desktop-input`,
                                autoFocus: false
                            }}
                            renderMenu={(children) => { return RenderMenu(children) }}
                            inputIcon={""}
                            defaultInputIcon={false}
                            isMobileView={false}
                            open={showSuggestionList}
                            onMenuVisibilityChange={(isOpen) => {
                                if (isOpen) {
                                    const referenceElement = document.getElementById(`searchWidgetContainer`);
                                    if (referenceElement) {
                                        const offsetTop = referenceElement.getBoundingClientRect().top - 80;
                                        // if(offsetTop>80){
                                        window.scroll({ top: offsetTop + window.scrollY, behavior: "smooth", });
                                        // }
                                    }
                                    setshowCalendar(false)
                                }
                            }}
                        />
                        <div className={[css['search-widget-location-error']].join(' ')}>
                            {errorObj.dest_err ? <div className={[css['search-widget-location-error-inner']].join(' ')}>{errorObj.dest_err}</div> : null}
                        </div>

                    </div>
                    {/* autosuggest */}


                    {/* calendar */}
                    <div className={[css['calender-container']].join(' ')} onClick={() => setshowCalendar(true)}>
                        <VmsDateRangeCalendar
                            indicatorColor="#fffff"
                            startDate={checkin}
                            endDate={checkout}
                            noofMonthView={2}
                            hideHeader={true}
                            inputBoxLabel={"Checkin date"}
                            mode={"singleDateRange"}
                            autoFocus
                            showEndDate
                            autoInputFocusData={{
                                leftInputViewLabel: "Check-in Date",
                                leftInputViewValue: new Date(),
                                rightInputViewLable: "Check-out Date",
                                rightInputViewValue: addDays(new Date(), 1),
                                inputWidth: '100px'
                            }
                            }
                            arrowImages={{
                                prevArrowImage: getLocalImagePath("prev_arrow_icon.svg"),
                                nextArrowImage: getLocalImagePath("next_arrow_icon.svg"),
                                prevArrowImageAlt: 'prev_Alt',
                                nextArrowImageAlt: 'next_Alt'
                            }}
                            showTodayDate
                            todaysDateCss={{ border: "2px solid #027BFF" }}
                            dateDisplayFormat={'dd LLL, yy'}
                            openCalendar={showCalendar}
                            disabledColor="#DEDEDE"
                            currentSelection="startDate"
                            onCalendarVisibilityChange={(isVisible) => {
                                setshowCalendar(isVisible)
                                if (isVisible) {
                                    const referenceElement = document.getElementById(`searchWidgetContainer`);

                                    if (referenceElement) {
                                        const offsetTop = referenceElement.getBoundingClientRect().top - 80;
                                        // if(offsetTop>80){
                                        window.scroll({ top: offsetTop + window.scrollY, behavior: "smooth", });
                                        // }
                                    }
                                    setshowSuggestionList(false)
                                }
                            }}
                            additionalClassName={['date-range-positionRelative', "search-date-range"]}
                            onSingleDateChange={(res) => { onDateSelectionChange(res) }}
                            showCalendarOnOutsideClick={false}
                            maxBookingDays={30}
                        />
                        <div>

                            <div className={[css['search-widget-date-error']].join(' ')}>
                                {errorObj.date_err ? <div className={[css['search-widget-date-error-inner']].join(' ')}>{errorObj.date_err}</div> : null}
                            </div>
                        </div>
                    </div>
                    {/* calendar */}


                    {/* pax selection */}
                    <div className={[css['pax-selection-container']].join(' ')}
                    //  onClick={() => setshowPaxDropdown(!showPaxDropdown)}
                    >
                        <label>No. of Guests and Rooms</label>
                        <div className={[css['pax-selection']].join(' ')}>
                            {rooms && Object.values(rooms).length > 0 && (
                                <VMSPaxSelection
                                    accessibleId="pax_selection_1"
                                    isMobile={false}
                                    adultAgeLabel={roomConfig.adultAgeLabel}
                                    childAgeLabel={roomConfig.childAgeLabel}
                                    adultAgeRangeLabel={roomConfig.adultAgeRangeLabel}
                                    childrenAgeRangeLabel={roomConfig.childAgeRangeLabel}
                                    defaultAdult={roomConfig.defaultAdultCount}
                                    maximumRoomPax={roomConfig.maximumRoomPax}
                                    minimumChildAge={roomConfig.minChildAge}
                                    maximumChildAge={roomConfig.maxChildAge}
                                    maxChildPerRoom={roomConfig.maxChildPerRoom}
                                    minimumPax={roomConfig.minPax}
                                    maximumTotalPax={roomConfig.maximumTotalPax}
                                    minAdultPerRoom={roomConfig.minAdultPerRoom}
                                    childAgeSuffix={roomConfig.childAgeSuffix}
                                    addMoreBtnLabel={"+ Add More Rooms"}
                                    AdultStepperProps={{
                                        images: {
                                            "plusImage": "/hotels/assets/images/activePlus.svg",
                                            "minusImage": "/hotels/assets/images/activeMinus.svg",
                                            "plusDisabledImage": "/hotels/assets/images/inActivePlus.svg",
                                            "minusDisabledImage": "/hotels/assets/images/inActiveMinus.svg"
                                        }
                                    }}
                                    ChildStepperProps={{
                                        images: {
                                            "plusImage": "/hotels/assets/images/activePlus.svg",
                                            "minusImage": "/hotels/assets/images/activeMinus.svg",
                                            "plusDisabledImage": "/hotels/assets/images/inActivePlus.svg",
                                            "minusDisabledImage": "/hotels/assets/images/inActiveMinus.svg"
                                        }
                                    }}
                                    onChildDropDownVisibiltyChange={(isVisible, childIndex, roomIndex) => { }}
                                    customChildDropdownLabel={"Child age"}
                                    childAgeDescription={<div className={getClassName(css['child-age-checkout-text'])}>
                                        <h3>Children age at check - in</h3>
                                        <p>Children age will be used to find you the best match in beds, rooms, size and special prices</p>
                                    </div>}
                                    paxData={rooms}
                                    onPaxVisibilityChange={(val) => {
                                        if (val) {
                                            const referenceElement = document.getElementById(`searchWidgetContainer`);
                                            if (referenceElement) {
                                                const offsetTop = referenceElement.getBoundingClientRect().top - 80;
                                                window.scroll({ top: offsetTop + window.scrollY, behavior: "smooth", });
                                            }
                                        }
                                        setshowPaxDropdown(val)
                                    }}
                                    openPaxSelection={showPaxDropdown}
                                    // paxChangeCallback={(roomData) => {
                                    //     setrooms(roomData)
                                    // }}
                                    paxChangeCallback={paxChangeCallback}
                                    onPaxInputClick={() => {
                                        setshowPaxDropdown(!showPaxDropdown)

                                    }}
                                    doneButton={{
                                        children: "Done",
                                        buttonType: "tertiary",
                                        onClick: (e) => { setshowPaxDropdown(false); handleSearch("Search") }
                                    }
                                    }
                                />
                            )}
                        </div>
                        <div className={[css['search-widget-pax-error']].join(' ')}>
                            {errorObj.pax_err ? <div className={[css['search-widget-pax-error-inner']].join(' ')}>{errorObj.pax_err}</div> : null}
                        </div>
                    </div>
                    {/* pax selection */}

                    <div className={[css['search-hotels-btn-container']].join(' ')}>
                        <VmsButton className={[css['search-btn']].join(' ')} onClick={() => { handleSearch("Search") }}>Search</VmsButton>
                    </div>
                </div>
            </div>


        )
    }

    function Type2Widget() {
        return (
            <Fragment>
                <section >
                    <div className={getClassName(css["input-going"])}>
                        <div className={getClassName(css["main-input-going"])} >
                            <div>
                                <div className={getClassName(css["did-floating-label-content"], css["modify-search-input"])} >
                                    <VmsVariantOfAutoComplete
                                        onInputClick={() => {
                                            setshowSuggestionList(!showSuggestionList)
                                            if (showCalendar) {
                                                setshowCalendar(!showCalendar)
                                            }
                                        }}
                                        items={autosuggestList}
                                        className="atou-testing"
                                        additionalClassName={[css["test-autocomplete"]].join(" ")}
                                        autoHighlight={false}
                                        getItemValue={(item) => { return item.title }}
                                        renderItem={RenderItem}
                                        value={autoSuggestValue}
                                        shouldItemRender={(item) => { return item.title }}
                                        onChange={(e) => callAutosuggestApi(e.target.value)}
                                        onFocus={(e) => {
                                            setSelectedDest({})
                                            setautoSuggestValue('')
                                            setautosuggestList(popularDestination)
                                            setshowSuggestionList(true)
                                        }}
                                        onSelect={(value, item) => {
                                            onSelect(value, item)
                                        }}
                                        selectOnBlur={false}
                                        inputProps={{
                                            placeholder: 'Search',
                                            id: 'search-flyout-mobile-input',
                                            className: `vms_variantOfautocomplete_searchinput ${css["input-class"]} new-class-modify-input`,

                                            // onKeyDown: (e) => {
                                            //     if (e.key === ' ' && !hasInputStarted) {
                                            //         e.preventDefault();
                                            //     } else {
                                            //         setHasInputStarted(true);
                                            //     }
                                            // }
                                        }}
                                        searchLabel="Where are you going?"
                                        labelClassName="did-floating-label"
                                        renderMenu={RenderMenu}
                                        inputIcon={""}
                                        open={showSuggestionList}
                                        onMenuVisibilityChange={(isOpen) => {
                                            if (isOpen) {
                                                setshowCalendar(false)
                                            }
                                        }}
                                    />


                                </div>
                                <div className={getClassName(css["location-error-message"])}>
                                    {errorObj.dest_err ? <div className={[css['search-widget-location-error-inner']].join(' ')}>{errorObj.dest_err}</div> : null}
                                </div>
                            </div>

                            <div className={getClassName([css["main-check-in"]])}>
                                <VmsDateRangeCalendar
                                    additionalClassName={getClassName([css["search-date-range"]])}
                                    startDate={checkin}
                                    endDate={checkout}
                                    noofMonthView={2}
                                    openCalendar={showCalendar}
                                    disabledColor="#DEDEDE"
                                    currentSelection="startDate"
                                    autoFocus
                                    mode={"singleDateRange"}
                                    showEndDate
                                    hideHeader={true}
                                    showTodayDate
                                    todaysDateCss={{ border: "2px solid #027BFF" }}
                                    customInputView={
                                        <div onClick={() => {
                                            setshowCalendar(!showCalendar)
                                        }}>
                                            <VmsInputText
                                                rightView={<Image src={getLocalImagePath('up_arrow.svg')} alt='arrow' width={24} height={24} style={{ width: '100%', height: 'auto', maxHeight: '100%' }} />}
                                                value={`${format(new Date(checkin), 'dd MMM') + '-' + format(new Date(checkout), ' dd MMM yyyy')}`}
                                            />
                                        </div>
                                    }
                                    accessibleId="date_range_calendar"
                                    onCalendarVisibilityChange={(isVisible) => {
                                        setshowCalendar(isVisible)
                                        if (isVisible) {
                                            setshowSuggestionList(false)
                                            const referenceElement = document.getElementById(`vms_DateRangeCalendar_PickerContainer`);
                                            if (referenceElement) {
                                                const offsetTop = referenceElement.getBoundingClientRect().top + window.pageYOffset - 100;
                                                window.scroll({ top: offsetTop, behavior: "smooth", });
                                            }
                                        }
                                    }}
                                    showCalendarOnOutsideClick={false}
                                    onSingleDateChange={(res) => { onDateSelectionChange(res) }}
                                    maxBookingDays={30}
                                />
                                <label className={getClassName([css["input-check-in"]])}>Check-in and Check-out Date</label>
                                <div className={getClassName([css["input-check-in-error-msg-container"]])}>{errorObj.date_err ? <div className={getClassName([css["input-check-in-error-msg-text"]])}>{errorObj.date_err}</div> : null}</div>
                            </div>


                            <div className={getClassName(css["main-guest-select-container"])}>
                                <div className={getClassName(css["main-guest-select"])}>
                                    <VMSPaxSelection
                                        accessibleId="pax_selection_1"
                                        isMobile={false}
                                        adultAgeLabel={roomConfig.adultAgeLabel}
                                        childAgeLabel={roomConfig.childAgeLabel}
                                        adultAgeRangeLabel={roomConfig.adultAgeRangeLabel}
                                        childrenAgeRangeLabel={roomConfig.childAgeRangeLabel}
                                        defaultAdult={roomConfig.defaultAdultCount}
                                        maximumRoomPax={roomConfig.maximumRoomPax}
                                        minimumChildAge={roomConfig.minChildAge}
                                        maximumChildAge={roomConfig.maxChildAge}
                                        maxChildPerRoom={roomConfig.maxChildPerRoom}
                                        minimumPax={roomConfig.minPax}
                                        maximumTotalPax={roomConfig.maximumTotalPax}
                                        minAdultPerRoom={roomConfig.minAdultPerRoom}
                                        childAgeSuffix={roomConfig.childAgeSuffix}
                                        addMoreBtnLabel={roomConfig.addMoreBtnLabel}
                                        customChildDropdownLabel={"Child age"}
                                        onChildDropDownVisibiltyChange={(isVisible, childIndex, roomIndex) => {
                                            //     if(isVisible){
                                            //     const referenceElement = document.getElementById(`vms_paxselection_paxmain_room_${roomIndex}_child_${childIndex}`);
                                            //     console.log("REFER",referenceElement)
                                            //     if (referenceElement) {
                                            //         const offsetTop = referenceElement.getBoundingClientRect().top + window.pageYOffset-100;
                                            //         window.scroll({ top: offsetTop, behavior: "smooth", });
                                            //     }        
                                            // }
                                        }} AdultStepperProps={{
                                            images: {
                                                "plusImage": "/hotels/assets/images/activePlus.svg",
                                                "minusImage": "/hotels/assets/images/activeMinus.svg",
                                                "plusDisabledImage": "/hotels/assets/images/inActivePlus.svg",
                                                "minusDisabledImage": "/hotels/assets/images/inActiveMinus.svg"
                                            }
                                        }}
                                        ChildStepperProps={{
                                            images: {
                                                "plusImage": "/hotels/assets/images/activePlus.svg",
                                                "minusImage": "/hotels/assets/images/activeMinus.svg",
                                                "plusDisabledImage": "/hotels/assets/images/inActivePlus.svg",
                                                "minusDisabledImage": "/hotels/assets/images/inActiveMinus.svg"
                                            }
                                        }}
                                        childAgeDescription={<div className={getClassName(css['child-age-checkout-text'])}>
                                            <h3>Children age at check - in</h3>
                                            <p>Children age will be used to find you the best match in beds, rooms, size and special prices</p>
                                        </div>}
                                        paxData={rooms}
                                        openPaxSelection={showPaxDropdown}
                                        doneButton={{
                                            children: "Done",
                                            buttonType: "tertiary",
                                            onClick: () => { setshowPaxDropdown(false); handleSearch("Modify Search") },
                                        }}
                                        onPaxInputClick={() => {

                                            const referenceElement = document.getElementById(`pax_selection_1`);
                                            if (referenceElement) {
                                                const offsetTop = referenceElement.getBoundingClientRect().top + window.pageYOffset - 100;
                                                window.scroll({ top: offsetTop, behavior: "smooth", });
                                            }

                                            setshowPaxDropdown(!showPaxDropdown)


                                        }}
                                        // paxChangeCallback={(roomData) => { setrooms(roomData) }}
                                        paxChangeCallback={paxChangeCallback}

                                        onPaxVisibilityChange={(val) => { setshowPaxDropdown(val) }}
                                    />
                                    <label className={getClassName(css["guests-check-in"])}>No. of Guest and Rooms</label>
                                </div>
                                <div className={getClassName(css["pax-error-message"])}>
                                    {errorObj.pax_err ? <div className={[css['search-widget-pax-error-inner']].join(' ')}>{errorObj.pax_err}</div> : null}
                                </div>
                            </div>

                            <div>
                                <div className={getClassName(css["search-button-container"])}>
                                    <VmsButton className={getClassName(css["search-button"])} value="Search" onClick={() => { handleSearch("Modify Search") }}>
                                        Modify Search
                                    </VmsButton>
                                </div>
                            </div>
                        </div>
                        {/* <hr className={getClassName(css["horizontal-line"])} /> */}
                    </div>
                </section>
            </Fragment>
        )
    }

    function switchWidget(type) {
        switch (type) {
            case "type1":
                return Type1Widget();
            case "type2":
                return Type2Widget();
            default:
                return Type1Widget();
        }

    }

    return (
        <Fragment>
            {(props.isLoading || loadingWidget) && props.LoadingComponent ? props.LoadingComponent : switchWidget(props.widgetType)}
        </Fragment>
    )

}
export default React.memo(SearchWidget);
