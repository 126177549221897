import React from "react";
import { Button } from "@vernost_dev/vms-react-components/dist/Button";

const VmsButton = (props) => {
  const {
    buttonType,
    additionalStyle,
    isMobile,
    isLoading,
    disabled,
    rippleEffect,
    children,
    className,
    onClick = () =>{},
  } = props;

  return (
    <Button
      buttonType={buttonType}
      additionalClassName={className}
      additionalStyle={additionalStyle}
      isMobile={isMobile}
      disabled={disabled}
      onClick={(e) => onClick(e)}
      isLoading={isLoading}
      rippleEffect={rippleEffect}
    >
      {children}
    </Button>
  );
};

export { VmsButton };
