// import { VariantOfAutocomplete } from "vms_react_components";
import { VariantOfAutocomplete } from "@vernost_dev/vms-react-components/dist/VariantOfAutocomplete";

const VmsVariantOfAutoComplete = (props) => {
  const {
    additionalClassName,
    defaultInputIcon,
    autoFocus,
    autoHighlight,
    inputIcon,
    getItemValue,
    renderItem,
    selectOnBlur,
    shouldItemRender,
    onChange = () => {},
    onSelect = () => {},
    onFocus = () => {},
    onBlur = () => {},
    onMouseEnter = () => {},
    onClickCloseButton = () => {},
    inputProps,
    renderMenu,
    additionalStyle,
    id,
    placeholder,
    items,
    open,
    autoComplete,
    onClick,
    value,
    searchLabel,
    labelClassName,   
    onMenuVisibilityChange = () => {},
    accessibleId
  } = props;

  
  return (
    <VariantOfAutocomplete
      items={items}
      searchLabel={searchLabel}
      additionClassName={additionalClassName}
      autoHighlight={autoHighlight}
      autoFocus={autoFocus}
      inputIcon={inputIcon}
      defaultInputIcon={defaultInputIcon}
      getItemValue={getItemValue}
      renderItem={renderItem}
      shouldItemRender={shouldItemRender}
      selectOnBlur={selectOnBlur}
      onChange={(e) => onChange(e)}
      onSelect={(value,item) => {        
        onSelect(value,item)}
      }
      onFocus={(e) => onFocus(e)}
      onBlur={(e) => onBlur(e)}
      inputProps={inputProps}
      renderMenu={renderMenu}
      additionalStyle={additionalStyle}
      id={id}
      isMobileView={props.isMobileView}
      onMouseEnter={onMouseEnter}
      placeholder={placeholder}
      value={value}
      onClick={onClick}
      open={open}
      labelClassName={labelClassName}
      onClickCloseButton={onClickCloseButton}
      onMenuVisibilityChange= {(val)=> onMenuVisibilityChange(val)}
      accessibleId={accessibleId}
    />
  );
};

export { VmsVariantOfAutoComplete };
