import React from "react";
import { PaxSelection } from "@vernost_dev/vms-react-components/dist/PaxSelection";

const VMSPaxSelection = (props) => {
  const {
    accessibleId,
    isMobile,
    adultAgeLabel,
    childAgeLabel,
    adultAgeRangeLabel,
    childrenAgeRangeLabel,
    defaultAdult,
    maximumRoomPax,
    minimumChildAge,
    maximumChildAge,
    maxChildPerRoom,
    minimumPax,
    childAgeSuffix,
    maximumTotalPax,
    minAdultPerRoom,
    addMoreBtnLabel,
    AdultStepperProps,
    ChildStepperProps,
    childAgeDescription,
    paxData,
    openPaxSelection,
    paxChangeCallback = () => { },
    doneButton,
    cancelButton,
    onPaxVisibilityChange = () => { },
    onPaxInputClick = () =>{},
    customChildDropdownLabel,
    onChildDropDownVisibiltyChange=()=>{}
  } = props;
  return <PaxSelection
    accessibleId={accessibleId}
    isMobile={isMobile}
    adultAgeLabel={adultAgeLabel}
    childAgeLabel={childAgeLabel}
    adultAgeRangeLabel={adultAgeRangeLabel}
    childrenAgeRangeLabel={childrenAgeRangeLabel}
    defaultAdult={defaultAdult}
    maximumRoomPax={maximumRoomPax}
    minimumChildAge={minimumChildAge}
    maximumChildAge={maximumChildAge}
    maxChildPerRoom={maxChildPerRoom}
    minimumPax={minimumPax}
    childAgeSuffix={childAgeSuffix}
    maximumTotalPax={maximumTotalPax}
    minAdultPerRoom={minAdultPerRoom}
    addMoreBtnLabel={addMoreBtnLabel}
    AdultStepperProps={AdultStepperProps}
    ChildStepperProps={ChildStepperProps}
    childAgeDescription={childAgeDescription}
    paxData={paxData}
    openPaxSelection={openPaxSelection}
    paxChangeCallback={(e) => { paxChangeCallback(e) }}
    doneButton={doneButton}
    cancelButton={cancelButton}
    onPaxVisibilityChange={(e) => onPaxVisibilityChange(e)}
    onPaxInputClick={()=>onPaxInputClick()}
    customChildDropdownLabel={customChildDropdownLabel}
    onChildDropDownVisibiltyChange={onChildDropDownVisibiltyChange}
  />;
};

export { VMSPaxSelection };